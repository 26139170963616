<template>
  <div>
    <div class="modular">
      <p class="modular-title">扫码查看小程序运营数据：</p>
      <div class="modular-content">
        <el-image class="wx-qrcode" :src="require('../static/images/wx-qrcode@2x.png')" fit="cover">
        </el-image>
        <div class="use-discript">
          <p style="color: rgba(0, 0, 0, 0.85)">使用说明：</p>
          <p>小程序管理员：扫描二维码即可打开小程序助手可以选择查看已绑定小程序的数据。</p>
          <p>其他微信用户：经管理员授权后可以查看已授权小程序的数据。</p>
        </div>
      </div>
    </div>
    <div class="modular">
      <p class="modular-title">数据示例</p>
      <div class="modular-content">
        <div style="padding: 0 12px;">
          <el-image class="example-img" :src="require('../static/images/example@2x.png')" fit="cover"
            @click="$previewImage([require('../static/images/example@2x.png')])">
          </el-image>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang='scss' scoped>
.modular + .modular {
  margin-top: 64px;
}
.modular-content {
  display: flex;
  align-items: center;
  .use-discript {
    p {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
    }

    p + p {
      margin-top: 22px;
    }
  }

  .wx-qrcode {
    width: 200px;
    height: 200px;
    margin-right: 70px;
  }
  .example-img {
    width: 878px;
    height: 567px;
  }
}
</style>